<template>
    <div :style="moduleStyle">
        <slot />
    </div>
</template>

<script setup>
import {$getModuleColor, appColors} from "@/utils/appModules"

const props = defineProps({
    module: {
        type: String
    }
})

const hasAppModulesFeature = useFeature('appModules')

const moduleStyle = computed(() => {
    if(!hasAppModulesFeature) return {}
    const moduleColors = $getModuleColor(props.module)
    const primaryColor = appColors && appColors['primary']
    return {
        '--primary-color-50': getRGBValue((moduleColors && moduleColors['50']) ?? primaryColor['50']),
        '--primary-color-100': getRGBValue((moduleColors && moduleColors['100']) ?? primaryColor['100']),
        '--primary-color-200': getRGBValue((moduleColors && moduleColors['200']) ?? primaryColor['200']),
        '--primary-color-300': getRGBValue((moduleColors && moduleColors['300']) ?? primaryColor['300']),
        '--primary-color-400': getRGBValue((moduleColors && moduleColors['400']) ?? primaryColor['400']),
        '--primary-color-500': getRGBValue((moduleColors && moduleColors['500']) ?? primaryColor['500']),
        '--primary-color-600': getRGBValue((moduleColors && moduleColors['600']) ?? primaryColor['600']),
        '--primary-color-700': getRGBValue((moduleColors && moduleColors['700']) ?? primaryColor['700']),
        '--primary-color-800': getRGBValue((moduleColors && moduleColors['800']) ?? primaryColor['800']),
        '--primary-color-900': getRGBValue((moduleColors && moduleColors['900']) ?? primaryColor['900']),
        '--primary-color-950': getRGBValue((moduleColors && moduleColors['950']) ?? primaryColor['950'])
    }
})

function hexToRgb(rgbOrHex) {
    const hex = rgbOrHex.replace('#', '')
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return `${r} ${g} ${b}`
}

function getRGBValue(rgbOrHex) {
    if (rgbOrHex.includes(' ')) {
        return rgbOrHex
    }
    return hexToRgb(rgbOrHex)
}


</script>
